<template>
  <b-card title="Detail Nasabah">
    <b-row>
      <b-col md="6" xl="4">
        <b-form-group label="KTP" label-for="id-card">
          <b-form-input
            id="id-card"
            max="16"
            type="number"
            v-model="form.nik"
            :change="updateValue"
          />
        </b-form-group>
      </b-col>
      <b-col md="6" xl="4">
        <b-form-group label="Tanggal Terdaftar" label-for="registerDate">
          <flat-pickr
            v-model="form.registerDate"
            class="form-control"
            :config="datepicker"
          />
        </b-form-group>
      </b-col>
      <b-col md="6" xl="4">
        <b-form-group label="Bukti Foto" label-for="imageRegister">
          <b-form-input id="image-register" v-model="form.imageRegister" />
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="12">
        <b-button block class="mt-2" variant="success">Kirim</b-button>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BButton,
} from "bootstrap-vue";
import flatPickr from "vue-flatpickr-component";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BButton,
    flatPickr,
  },
  data() {
    return {
      datepicker: {
        dateFormat: "d-m-Y",
      },
      form: {
        nik: "",
        registerDate: null,
        imageRegister: null,
      },
    };
  },
  created() {
    this.$http
      .get("/products")
      .then((response) => {
        this.product = response.data.data;
      })
      .catch((error) => {
        console.log(error);
      });

    this.$http
      .get("/jobs")
      .then((response) => {
        this.job = response.data.data;
      })
      .catch((error) => {
        console.log(error);
      });

    this.$http
      .get("/genders")
      .then((response) => {
        this.gender = response.data.data;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  methods: {
    updateValue(e) {
      console.log(this.form.nik);
    },
    onSend() {
      this.$http
        .post("/policy", this.form)
        .then((res) => {
          this.$bvToast.toast("Berhasil membuat polis baru", {
            title: "Berhasil",
            variant: "success",
            solid: true,
            autoHideDelay: 1000,
          });
          this.$router.push("/policies");
        })
        .catch((err) => {
          console.log(err);
          this.$bvToast.toast(err.response.data.message, {
            title: "Error",
            variant: "danger",
            solid: true,
            autoHideDelay: 1000,
          });
        });
    },
    makeToast(variant = null) {
      this.$bvToast.toast("Toast body content", {
        title: `Variant ${variant || "default"}`,
        variant,
        solid: true,
      });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
